import styled from "styled-components";

interface Props {
	width?: string;
	height?: string;
	display?: "inline" | "flex" | "grid";
	position?: "static" | "relative" | "absolute" | "fixed";
	left?: string;
	right?: string;
	top?: string;
	bottom?: string;
	justify?: "start" | "center" | "end" | "space-between";
	align?: "start" | "center" | "end";
	direction?: "column";
	gap?: string;
}

export const Box = styled.div<Props>`
	display: ${({ display = "flex" }) => display};
	width: ${({ width }) => width};
	height: ${({ height }) => height};
	position: ${({ position }) => position};
	left: ${({ left }) => left};
	right: ${({ right }) => right};
	top: ${({ top }) => top};
	bottom: ${({ bottom }) => bottom};
	justify-content: ${({ justify }) => justify};
	align-items: ${({ align }) => align};
	flex-direction: ${({ direction }) => direction};
	gap: ${({ gap }) => gap};
`;
