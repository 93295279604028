import { useRef, useState } from "react";
import styled from "styled-components";
import { useStyleStates } from "../../utils/styleStates";

const ButtonElement = styled.button<{
	width?: string;
	isHovered: boolean;
	isActive: boolean;
}>`
	position: relative;
	width: ${({ width }) => width};
	padding: 12px 28px;
	border-radius: 50px;
	border-width: 2px;
	border-style: solid;

	justify-content: center;
	align-items: center;
	text-transform: uppercase;
	font-weight: bold;
	cursor: pointer;

	background-color: ${({ isHovered, isActive }) =>
		(isActive && "#226a8b") || (isHovered && "#3f92b7") || "white"};
	border-color: ${({ isHovered, isActive }) =>
		(isActive && "#3f92b7") || (isHovered && "#3181a7") || "#3f92b7"};
	color: ${({ isHovered, isActive }) =>
		(isActive && "white") || (isHovered && "white") || "#3f92b7"};
	box-shadow: ${({ isHovered }) => (isHovered && "0 0 20px #54c9ff") || "none"};
`;

const Link = styled.a`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
`;

interface Props {
	width?: string;
	url?: string;
	children?: React.ReactNode;
}

export const Button = ({ width, url, children }: Props) => {
	const [isHovered, setIsHovered] = useState(false);
	const [isActive, setIsActive] = useState(false);

	const ref = useRef<HTMLButtonElement>(null);
	useStyleStates({
		ref,
		setIsHovered,
		setIsActive,
	});

	return (
		<ButtonElement ref={ref} width={width} isHovered={isHovered} isActive={isActive}>
			{children}

			{url && <Link href={url} target="_blank" draggable={false} />}
		</ButtonElement>
	);
};
