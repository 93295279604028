import { styled } from "styled-components";
import { Box, Button } from "./elements";

const Background = styled.div`
	height: 100vh;
	width: 100vw;
	background-position: top center;
	background-size: contain;
	background-repeat: no-repeat;
	background-image: url("/img/cover.png");

	@media (max-width: 800px) {
		background-size: cover;
		background-image: url("/img/cover_portrait.png");
	}
`;

const Text = styled.div`
	text-transform: uppercase;
	font-size: 1.3em;
	font-weight: bold;
	opacity: 0.6;
`;
export const App = () => {
	return (
		<>
			<Background />

			<Box
				direction="column"
				position="fixed"
				bottom="5vh"
				left="0"
				width="100vw"
				justify="center"
				align="center"
				gap="30px"
			>
				<Text>Web page is currently under development...</Text>

				<Button width="340px" url="https://www.facebook.com/HiddenFlameDesign">
					Check out the Facebook Page
				</Button>
			</Box>
		</>
	);
};
