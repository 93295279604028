import { useEffect, useRef } from "react";

interface Props {
	ref: React.RefObject<HTMLElement>;
	setIsHovered: (isHovered: boolean) => void;
	setIsActive: (isActive: boolean) => void;
}

export const useStyleStates = ({ ref, setIsHovered, setIsActive }: Props) => {
	const isActiveHelper = useRef(false);

	const onEnter = () => {
		setIsHovered(true);
		setIsActive(isActiveHelper.current);
	};

	const onLeave = () => {
		setIsHovered(false);
		setIsActive(false);
	};

	const onDown = () => {
		setIsActive(true);
		isActiveHelper.current = true;
		document.addEventListener("pointerup", onUp, { once: true });
	};

	const onUp = () => {
		setIsActive(false);
		isActiveHelper.current = false;
	};

	useEffect(() => {
		ref.current?.addEventListener("pointerenter", onEnter);
		ref.current?.addEventListener("pointerleave", onLeave);
		ref.current?.addEventListener("pointerdown", onDown);
	}, []);
};
